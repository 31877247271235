function Home() {
    return (
        <div className="row justify-content-center mt-4 pt-4">
            <div className="col-12 text-center">
                <h1>Was ist der Aszendent?</h1>
                <p>
                    Der Aszendent, abgekürzt AC, ist das Tierkreiszeichen, das sich bei einer Person bei ihrer Geburt am östlichen Horizont zeigt. Er markiert den Punkt, an dem der östliche Horizont die Ekliptik schneidet. Dieses Zeichen ändert sich alle zwei Stunden aufgrund der Erdachsenbewegung und durchläuft den gesamten Tierkreis in 24 Stunden. Daher sind der genaue Geburtsort und die Geburtszeit erforderlich, um den persönlichen Aszendenten zu berechnen.
                </p>
                <p>
                    Die Berechnung des Aszendenten ist ein wichtiger Schritt in der Astrologie. Um Ihren persönlichen Aszendenten zu ermitteln, benötigen Sie den genauen Geburtsort und die exakte Geburtszeit. Astrologen verwenden dafür spezielle Werkzeuge wie die Swiss Ephemeris, um den Zeitpunkt zu bestimmen, zu dem Ihr Aszendent am östlichen Horizont aufgeht. Dieser individuelle Aszendent spielt eine entscheidende Rolle bei der Interpretation Ihres Geburtshoroskops und beeinflusst Ihre Persönlichkeit sowie Ihre Lebensrichtung. Die genaue Berechnung Ihres Aszendenten ermöglicht es Astrologen, Ihnen präzise und maßgeschneiderte astrologische Analysen anzubieten.
                </p>
            </div>
        </div>
    );
}

export default Home;