export const COUNTIES = [
    { id: 1, name: 'Baden-Württemberg', longitude: 9.0, latitude: 48.6 },
    { id: 2, name: 'Bayern', longitude: 11.5, latitude: 48.8 },
    { id: 3, name: 'Berlin', longitude: 13.4, latitude: 52.5 },
    { id: 4, name: 'Brandenburg', longitude: 13.9, latitude: 52.4 },
    { id: 5, name: 'Bremen', longitude: 8.8, latitude: 53.1 },
    { id: 6, name: 'Hamburg', longitude: 10.0, latitude: 53.6 },
    { id: 7, name: 'Hessen', longitude: 9.0, latitude: 51.1 },
    { id: 8, name: 'Mecklenburg-Vorpommern', longitude: 12.7, latitude: 53.6 },
    { id: 9, name: 'Niedersachsen', longitude: 9.7, latitude: 52.8 },
    { id: 10, name: 'Nordrhein-Westfalen', longitude: 7.6, latitude: 51.5 },
    { id: 11, name: 'Rheinland-Pfalz', longitude: 7.4, latitude: 49.8 },
    { id: 12, name: 'Saarland', longitude: 6.9, latitude: 49.4 },
    { id: 13, name: 'Sachsen', longitude: 13.4, latitude: 51.0 },
    { id: 14, name: 'Sachsen-Anhalt', longitude: 11.6, latitude: 52.1 },
    { id: 15, name: 'Schleswig-Holstein', longitude: 9.7, latitude: 54.5 },
    { id: 16, name: 'Thüringen', longitude: 10.9, latitude: 50.9 },
];