import React, { useState } from 'react';

import AscendantResult from '../components/AscendantResult';

import calculateAscendant from './calculate_ascendant.js';
import { COUNTIES } from '../constants/constants';
function AscendantForm() {
    const [ascendant, setAscendant] = useState('');
    const [ascendantImage, setAscendantImage] = useState('');

    const getImage = (ascendant: string) => {
            switch (ascendant) {
                case 'Aries':
                    return '/images/aries.png';
                case 'Taurus':
                    return '/images/taurus.png';
                case 'Gemini':
                    return '/images/gemini.png';
                case 'Cancer':
                    return '/images/cancer.png';
                case 'Leo':
                    return '/images/leo.png';
                case 'Virgo':
                    return '/images/virgo.png';
                case 'Libra':
                    return '/images/libra.png';
                case 'Scorpio':
                    return '/images/scorpio.png';
                case 'Sagittarius':
                    return '/images/sagittarius.png';
                case 'Capricorn':
                    return '/images/capricorn.png';
                case 'Aquarius':
                    return '/images/aquarius.png';
                case 'Pisces':
                    return '/images/pisces.png';
                default:
                    return '';
            }
        };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Get the form data here
        const birthdate = (document.getElementById("birthdate") as HTMLInputElement).value;
        const birthtime = (document.getElementById("birthtime") as HTMLInputElement).value;
        const birthlocation = parseInt((document.getElementById("birthlocation") as HTMLInputElement).value);

        console.log(birthlocation)
        console.log(typeof birthlocation)

        const birthlocation_obj = COUNTIES.find((element) => element.id === birthlocation);

        const year = birthdate.slice(0, 4);
        const month = birthdate.slice(5, 7);
        const day = birthdate.slice(8, 10);
        const hour = birthtime.slice(0, 2);
        const minute = birthtime.slice(3, 5);
        const latitude = birthlocation_obj?.latitude;
        const longitude = birthlocation_obj?.longitude;

        const calculatedAscendant = calculateAscendant(year, month, day, hour, minute, latitude, longitude);

        setAscendant(calculatedAscendant._ascendant.Sign.label);
        setAscendantImage(getImage(calculatedAscendant._ascendant.Sign.label));
    };

    return (
        <div className="row justify-content-center">
            <div className="col-12 text-center">
                <h1>Aszendent Berechnen</h1>
                <form onSubmit={handleSubmit} className="mb-3">
                    <fieldset>
                    <div className="form-group">
                        <label htmlFor="birthdate" className="form-label">Dein Geburtstag</label>
                        <input type="date" className="form-control" id="birthdate" defaultValue="1992-11-25" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="birthtime" className="form-label">Deine Geburtszeit</label>
                        <input type="time" className="form-control" id="birthtime" defaultValue="08:27:00" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="birthlocation" className="form-label">Dein Geburtsort</label>
                        <select className="form-control" id="birthlocation" defaultValue="Niedersachsen">
                            {Object.entries(COUNTIES).map(([key, value]) => {
                                return (
                                    <option key={key} value={value.id}>{value.name}</option>
                                );
                            })}
                        </select>
                    </div>
                    </fieldset>
                    <button type="submit" className="btn btn-primary btn-block mt-4">Aszendent Berechnen</button>
                </form>
                <AscendantResult title={ascendant} image={ascendantImage} />

            </div>
        </div>
    );
}

export default AscendantForm;