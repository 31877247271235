import React from 'react';
import RoutingBase from './containers/RoutingBase';
import './App.css';

function App() {
  return (
    <RoutingBase />
  );
}

export default App;
