import React from 'react';

type AscendantResultProps = {
    title: string;
    image: string;
};

function AscendantResult(props: AscendantResultProps) {
    return (
        <div>
            <h1>{props.title}</h1>
            {props.title && (
                <img
                    src={props.image}
                    alt={props.title}
                    className="img-fluid"
                />
            )}
        </div>
    );
};

export default AscendantResult;
