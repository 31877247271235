// Base page that is passed children and props
function BasePage(props: any) {
    return (
        <>
            <body>
                <div
                    className="container-fluid"
                >
                    {/* Include common layout elements here */}
                    <header>
                        <h1>{props.title}</h1>
                    </header>
                    <main>
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-6">
                                {props.children}
                            </div>
                        </div>
                    </main>
                    <footer className="text-center">
                        <p><strong>aszendent-berechnen.net⭐</strong></p>
                    </footer>
                </div>
            </body>
        </>
    );
}

export default BasePage;
