import { Origin, Horoscope } from "circular-natal-horoscope-js";

export default function calculateAscendant(year, month, date, hour, minute, latitude, longitude) {
    const origin = new Origin({
        year: year,
        month: month,
        date: date,
        hour: hour,
        minute: minute,
        latitude: latitude,
        longitude: longitude,
    });

    const horoscope = new Horoscope({
        origin: origin,
        houseSystem: "whole-sign",
        zodiac: "sidereal",
        aspectPoints: ['bodies', 'points', 'angles'],
        aspectWithPoints: ['bodies', 'points', 'angles'],
        aspectTypes: ["major", "minor"],
        customOrbs: {},
        language: 'en'
    });

    return horoscope;
}
