import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import BasePage from '../components/BasePage';
import AscendantForm from "../components/AscendantForm";
import Home from '../components/Home';


export default function RoutingBase() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}

function HomePage() {
  return (
      <BasePage>
        <AscendantForm />
        <Home />
      </BasePage>
  );
}